export default {
    background: {
        blue: require('./Background/blue.svg'),
        dark: require('./Background/dark.svg'),
        green: require('./Background/green.svg'),
        grey: require('./Background/grey.svg'),
        pink: require('./Background/pink.svg'),
        purple: require('./Background/purple.svg'),
        red: require('./Background/red.svg'),
        teal: require('./Background/teal.svg'),
        white: require('./Background/white.svg'),
        yellow: require('./Background/yellow.svg'),
    },
    body: {
        checkered: require('./Body/checkered.svg'),
        round: require('./Body/round.svg'),
        small: require('./Body/small.svg'),
        square: require('./Body/square.svg'),
    },
    eyes: {
        glasses: require('./Eyes/glasses.svg'),
        happy: require('./Eyes/happy.svg'),
        open: require('./Eyes/open.svg'),
        sleepy: require('./Eyes/sleepy.svg'),
        sunglasses: require('./Eyes/sunglasses.svg'),
        wink: require('./Eyes/wink.svg'),
    },
    facialhair: {
        'mustache-black': require('./FacialHair/mustache-black.svg'),
        'goatee': require('./FacialHair/goatee.svg'),
        'shadow': require('./FacialHair/shadow.svg'),
        'soulpatch': require('./FacialHair/soulpatch.svg'),
        'walrus': require('./FacialHair/walrus.svg'),
    },
    hair: {
        bald: require('./Hair/bald.svg'),
        balding: require('./Hair/balding.svg'),
        beanie: require('./Hair/beanie.svg'),
        bigcurls: require('./Hair/bigcurls.svg'),
        bobbangs: require('./Hair/bobbangs.svg'),
        bobcut: require('./Hair/bobcut.svg'),
        buncurls: require('./Hair/buncurls.svg'),
        bunundercut: require('./Hair/bunundercut.svg'),
        buzz: require('./Hair/buzz.svg'),
        buzzcut: require('./Hair/buzzcut.svg'),
        curlybun: require('./Hair/curlybun.svg'),
        curlyhightop: require('./Hair/curlyhightop.svg'),
        fade: require('./Hair/fade.svg'),
        hat: require('./Hair/hat.svg'),
        long: require('./Hair/long.svg'),
        mohawk: require('./Hair/mohawk.svg'),
        pigtails: require('./Hair/pigtails.svg'),
        shortcombover: require('./Hair/shortcombover.svg'),
        shortcomboverchops: require('./Hair/shortcomboverchops.svg'),
        sidebuzz: require('./Hair/sidebuzz.svg'),
        straightbun: require('./Hair/straightbun.svg')
    },
    mouth: {
        bigsmile: require('./Mouth/bigsmile.svg'),
        frown: require('./Mouth/frown.svg'),
        lips: require('./Mouth/lips.svg'),
        pacifier: require('./Mouth/pacifier.svg'),
        smile: require('./Mouth/smile.svg'),
        smirk: require('./Mouth/smirk.svg'),
        surprise: require('./Mouth/surprise.svg'),
    },
    nose: {
        mediumround: require('./Nose/mediumround.svg'),
        smallround: require('./Nose/smallround.svg'),
        wrinkles: require('./Nose/wrinkles.svg'),
    },
    skin: {
        'skin': require('./Skin/skin.svg'),
    },
    brand: {
        'elrond-black': require('./Brand/elrond-black.svg'),
        'elrond-white': require('./Brand/elrond-white.svg'),
        eclair: require('./Brand/eclair.svg'),
        maiatar: require('./Brand/maiatar.svg'),
    }
};
